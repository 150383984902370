import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    IBackOrderConfig,
    IBackOrderItem,
    IBackOrderLabels,
    IBackOrderResponse,
} from 'components/BackOrders/interfaces/BackOrderInterfaces';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';

const mapStateToProps = (state) => {
    return {
        backOrderResponse: state.entities?.backOrderResponse as IBackOrderResponse,
    };
};

const useBackOrders = (): IBackOrderConfig => {
    const {} = useRequest(
        request({
            type: 'backOrderResponse',
            url: 'backorders/ajax/index',
            method: 'GET',
            notApi: true,
        }),
    );

    const { backOrderResponse } = useSelector(mapStateToProps);
    const [backOrderItems, setBackOrderItems] = useState<IBackOrderItem[]>([]);
    const [success, setSuccess] = useState(true);
    const [message, setMessage] = useState('');
    const [labels, setLabels] = useState<IBackOrderLabels>({} as IBackOrderLabels);
    const [isInitialized, setIsInitialized] = useState(false);
    const [backorderAllowed, setBackorderAllowed] = useState(false);

    useEffect(() => {
        if (backOrderResponse) {
            setBackOrderItems(backOrderResponse.backOrderItems);
            setSuccess(backOrderResponse.success);
            setMessage(backOrderResponse.message);
            if (!isInitialized && backOrderResponse.labels) {
                setLabels(backOrderResponse.labels);
            }
            if (!isInitialized && backOrderResponse.backorderAllowed) {
                setBackorderAllowed(backOrderResponse.backorderAllowed);
            }
            setIsInitialized(true);
            if (message) {
                Toaster.addToast({ intent: 'danger', text: message });
            }
        }
    }, [backOrderResponse]);

    return {
        backOrderItems,
        success,
        message,
        labels,
        isInitialized,
        backorderAllowed,
    };
};

export default useBackOrders;
