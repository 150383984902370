import React, { useState } from 'react';
import IProduct from 'components/Catalog/interfaces/IProduct';
import Img from 'components/Image/Img';
import useAuth from '../../../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { HttpStatusCode } from '../../../../../enums/HttpStatusCode';
import AddToCartRequest, { IAddToCartRequestData } from 'components/Catalog/Product/requests/AddToCartRequest';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import ProductPrice from 'components/Catalog/Product/components/ProductPrice';
import useBackOrders from '../../../../../hooks/useBackOrders';
import BackOrderCreateOverlay from 'components/BackOrders/Overylays/BackOrderCreateOverlay';
import { IBackOrderConfig, IBackOrderResponse } from 'components/BackOrders/interfaces/BackOrderInterfaces';

interface IProps {
    product: IProduct;
    backOrders: IBackOrderConfig;
    inBackOrders: boolean;
    labels: {
        logInForPrices: string;
        startingFrom: string;
        addToCart: string;
        quantity: string;
        readMore: string;
        outOfStock: string;
    };
}

const ProductActions: React.FunctionComponent<IProps> = (props) => {
    const { product, backOrders, inBackOrders, labels } = props;
    const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1);
    const [backOrderQty, setBackOrderQty] = useState<undefined | number>();
    const [backOrderMessage, setBackOrderMessage] = useState<undefined | string>();
    const [showBackOrderOverlay, setShowBackOrderOverlay] = useState(false);

    const showBackOrders = product.canAddToCart && backOrders.isInitialized && backOrders.backorderAllowed;

    const openLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    const [{ isPending }, addToCartRequest] = useMutation((data: IAddToCartRequestData) => AddToCartRequest(data));

    const handleAddToCart = () => {
        void addToCartRequest({
            sku: product.sku,
            qty: qty,
        }).then((res) => {
            if (res.status === HttpStatusCode.CREATED) {
                setBackOrderQty(res.body.quantityBackOrder);
                setBackOrderMessage(res.body.backOrderMessage);

                if (res.body.quantityBackOrder > 0) {
                    handleCreateBackOrder();
                }
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: res.body.message,
                });
            }
        });
    };

    const handleCreateBackOrder = () => {
        setShowBackOrderOverlay(true);
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: `backOrderCreate${product.sku}` }));
    };

    if (!product.isAllowedToSell && isLoggedIn) {
        return <></>;
    }

    return (
        <>
            {!isLoggedIn && (
                <button className="button button-discrete" onClick={openLogin}>
                    <span>
                        <span className="icon">
                            <Img iconName="icon_lock" />
                        </span>
                        {labels.logInForPrices}
                    </span>
                </button>
            )}
            {isLoggedIn && (
                <>
                    {showBackOrderOverlay && (
                        <BackOrderCreateOverlay
                            quantity={backOrderQty ?? qty}
                            sku={product.sku}
                            inBackOrders={inBackOrders}
                            descriptionLabel={backOrderMessage ?? backOrders.labels.backOrderOverlayDescription}
                            cancelLabel={backOrders.labels.backOrderOverlayCancel}
                            continueLabel={backOrders.labels.backOrderOverlayConfirm}
                            alreadyInBackOrders={backOrders.labels.alreadyInBackOrders}
                            inBackOrdersLabel={backOrders.labels.inBackOrdersLabel}
                        />
                    )}
                    {product.showPrice && (
                        <div className="box-product__prices">
                            <ProductPrice
                                priceAsLowAsLabel={product.typeId === 'configurable' ? labels.startingFrom : undefined}
                                finalPrice={product.specialPrice ?? product.price}
                                price={product.originalPrice}
                                productUnit={product.productUnit}
                                pricePerUnit={product.pricePerUnit}
                            />
                        </div>
                    )}
                    {product.typeId !== 'simple' && product.canAddToCart && (
                        <Button intent="secondary" title={labels.readMore} type="anchor" href={product.url} />
                    )}
                    {product.typeId === 'simple' && product.canAddToCart && (
                        <>
                            {(product.isSaleable || showBackOrders) && (
                                <div className="box-product__quantity">
                                    <label htmlFor="q2">{labels.quantity}:</label>
                                    <input
                                        id="q2"
                                        type="number"
                                        step="1"
                                        aria-label={labels.quantity}
                                        value={qty > 0 ? qty : ''}
                                        onChange={(e) => setQty(parseInt(e.target.value))}
                                    />
                                </div>
                            )}
                            {product.isSaleable && (
                                <Button
                                    icon="plus"
                                    intent="primary"
                                    title={labels.addToCart}
                                    onClick={() => handleAddToCart()}
                                    loading={isPending}
                                    disabled={isPending}
                                />
                            )}
                            {!product.isSaleable && !showBackOrders && (
                                <Button type="anchor" intent="secondary" title={labels.outOfStock} href={product.url} />
                            )}
                            {!product.isSaleable && showBackOrders && (
                                <Button
                                    icon="plus"
                                    type="anchor"
                                    intent="secondary"
                                    title={backOrders.labels.addToBackOrders}
                                    onClick={handleCreateBackOrder}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ProductActions;
