import { request } from 'data/requests/request';

interface IBackOrderCreateRequestData {
    sku: string;
    quantity: number;
}

export const CreateBackOrderRequest = (data: IBackOrderCreateRequestData) =>
    request({
        url: 'backorders/ajax/create',
        method: 'POST',
        notApi: true,
        data: data,
        type: 'backOrderResponse',
    });
