import React from 'react';
import { IListProduct } from 'components/BackOrders/interfaces/BackOrderInterfaces';
import { formatPriceLocale } from '../../../../helpers/priceUtils';
import Img from 'components/Image/Img';
import CartProductPricing from 'components/CartView/components/CartProductPricing';
import CartProductQuantity from 'components/CartView/components/CartProductQuantity';
import { useMutation } from 'redux-query-react';
import { UpdateBackOrderRequest } from 'components/BackOrders/interfaces/requests/UpdateBackOrderRequest';

interface IProps {
    product: IListProduct;
    labels: {
        totalLabel: string;
        removeLabel: string;
        quantityLabel: string;
        invalidQuantityLabel: string;
        updateLabel: string;
    };
}

const ListProductActions = (props: IProps) => {
    const { product, labels } = props;
    const { quantityLabel, invalidQuantityLabel, updateLabel, totalLabel, removeLabel } = labels;
    const [{}, updateBackOrder] = useMutation((sku: string, quantity: number) =>
        UpdateBackOrderRequest({ sku: sku, quantity: quantity }),
    );

    const removeProduct = () => {
        void updateBackOrder(product.sku, 0);
    };

    const updateProduct = (quantity: number) => {
        void updateBackOrder(product.sku, quantity);
    };

    return (
        <div className="box-product__actions">
            <div className="box-product__actions-group">
                <CartProductPricing price={product.price} specialPrice={product.specialPrice} />
                <CartProductQuantity
                    product={product}
                    updateProduct={updateProduct}
                    labels={{
                        quantityLabel: quantityLabel,
                        invalidQuantity: invalidQuantityLabel,
                        updateLabel: updateLabel,
                    }}
                />
            </div>
            <div className="box-product__actions-group">
                <div className="box-product__total">
                    <p>
                        <span className="box-product__total-prices">
                            <span className="text-accented">
                                {`${totalLabel}: ${formatPriceLocale(product.rowTotal)}`}
                            </span>
                        </span>
                    </p>
                </div>
                {!product.isSpecialOffer && (
                    <button className="box-product__remove" onClick={() => removeProduct()}>
                        <Img iconName={'icon_trash'} />
                        {removeLabel}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ListProductActions;
