import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import { useMutation } from 'redux-query-react';
import { CreateBackOrderRequest } from 'components/BackOrders/interfaces/requests/CreateBackOrderRequest';

interface IProps {
    quantity: number;
    sku: string;
    inBackOrders: boolean;
    descriptionLabel: string;
    continueLabel: string;
    cancelLabel: string;
    alreadyInBackOrders: string;
    inBackOrdersLabel: string;
    onBackOrderAdd?: (message: string) => void;
}

const BackOrderCreateOverlay = (props: IProps) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const [{}, createBackOrder] = useMutation(() =>
        CreateBackOrderRequest({ sku: props.sku, quantity: props.quantity }),
    );
    const {
        alreadyInBackOrders,
        cancelLabel,
        continueLabel,
        descriptionLabel,
        inBackOrders,
        inBackOrdersLabel,
        onBackOrderAdd,
        quantity,
        sku,
    } = props;

    const closeBackOrderCreate = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    const addToBackOrders = () => {
        void createBackOrder().then((result) => {
            if (onBackOrderAdd) {
                onBackOrderAdd(inBackOrdersLabel);
            }
        });
    };

    return (
        <Overlay
            isOpen={includes(openOverlays, `backOrderCreate${sku}`)}
            doClose={closeBackOrderCreate}
            layout="dialog"
            dialogConfig={{
                description: inBackOrders ? alreadyInBackOrders : descriptionLabel,
                buttons: [
                    {
                        title: continueLabel,
                        intent: 'primary',
                        onClick: addToBackOrders,
                    },
                    {
                        title: cancelLabel,
                        onClick: closeBackOrderCreate,
                    },
                ],
            }}
        />
    );
};

export default BackOrderCreateOverlay;
