import React from 'react';
import { ICartLabels } from 'components/CartView/CartView';
import ProductBadges from 'components/Catalog/Product/List/BoxProduct/ProductBadges';
import { IListProduct } from 'components/BackOrders/interfaces/BackOrderInterfaces';
import ListProductActions from 'components/BackOrders/Components/ListProducts/ListProductActions';

interface IProps {
    product: IListProduct;
    labels: ICartLabels;
}

const ListProduct: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;

    return (
        <div className="cart-products__item">
            <div className="box-product">
                <div className="box-product__main">
                    <a href={product.url} className="box-product__link">
                        {product.name}
                    </a>
                    <div className="box-product__image">
                        {!!product.badges.length && <ProductBadges badges={product.badges} />}
                        <span>
                            <img src={product.thumbnail} alt={product.name} />
                        </span>
                    </div>
                    <div className="box-product__content">
                        <div className="box-product__text">
                            <div className="box-product__title">{product.name}</div>
                            <div className="box-product__description">
                                <p className="box-product__description-primary">{product.arrivalDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ListProductActions product={product} labels={labels} />
            </div>
        </div>
    );
};

export default ListProduct;
