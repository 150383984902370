import React from 'react';
import Img from 'components/Image/Img';
import { IListProduct } from 'components/BackOrders/interfaces/BackOrderInterfaces';
import ListProduct from './ListProduct';
import { ICartLabels } from 'components/CartView/CartView';

interface IProps {
    products: IListProduct[];
    title: string;
    icon: string;
    cartLabels: ICartLabels;
}

const ListProducts: React.FunctionComponent<IProps> = (props) => {
    const { products, title, icon, cartLabels } = props;

    return (
        <div className="cart-products">
            <div className="cart-products__title">
                <h2>
                    <Img iconName={icon} alt={title} />
                    {title}
                </h2>
            </div>
            {products.map((product, i) => (
                <ListProduct key={`${product.sku}-${i}`} labels={cartLabels} product={product} />
            ))}
        </div>
    );
};

export default ListProducts;
