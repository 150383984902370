import React, { useState } from 'react';
import BackOrdersBanner from 'components/BackOrders/Components/BackOrdersBanner';
import useAuth from '../../../hooks/useAuth';

interface IProps {
    config: IConfig;
}

interface IConfig {
    labels: {
        backOrderFillDescription: string;
    };
}
export const LOCALSTORAGE_FILL_BACKORDERS = 'fill_backorders';
const BackOrderHandler = (props: IProps) => {
    const { config } = props;
    const { labels } = config;
    const { isLoggedIn } = useAuth();
    const [fillBackOrders, setFillBackOrders] = useState(localStorage.getItem(LOCALSTORAGE_FILL_BACKORDERS));

    if (!isLoggedIn) {
        return null;
    }

    if (fillBackOrders && fillBackOrders === '1') {
        return null;
    }

    return <BackOrdersBanner backOrderFillDescription={labels.backOrderFillDescription} />;
};

export default BackOrderHandler;
