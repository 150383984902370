import React, { useState } from 'react';
import IProduct from 'components/Catalog/interfaces/IProduct';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import { find, pick } from 'lodash';
import ButtonIcon from 'ekaubamaja-ui/lib/Components/ButtonIcon';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';
import ProductActions from 'components/Catalog/Product/List/BoxProduct/ProductActions';
import formDataRequest from 'data/requests/formDataRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications/index';
import useBackOrders from '../../hooks/useBackOrders';

interface IProps {
    config: {
        products: IProduct[];
        comparableAttributes: Array<{
            code: string;
            label: string;
        }>;
        labels: {
            addedToCart: string;
            logInForPrices: string;
            startingFrom: string;
            addToCart: string;
            quantity: string;
            readMore: string;
            outOfStock: string;
            noItemsToCompare: string;
            mobileCta: string;
            remove: string;
            removedProductFromCompare: string;
            failedToRemoveProductFromCompare: string;
        };
    };
}

const ProductCompareList: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { comparableAttributes, labels } = config;
    const backOrders = useBackOrders();

    const [products, setProducts] = useState(config.products);

    const removeProduct = (product: IProduct) => {
        formDataRequest(
            '/catalog/product_compare/remove',
            { form_key: window.form_key, product: product.id, isAjax: true },
            (error) => {
                if (error) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: labels.failedToRemoveProductFromCompare,
                    });
                } else {
                    Toaster.addToast({
                        intent: 'success',
                        text: labels.removedProductFromCompare.replace('%1', product.name),
                    });

                    setProducts((prevState) => prevState.filter((cmpProduct) => cmpProduct.sku !== product.sku));
                }
            },
        );
    };

    const getAttributeValue = (product: IProduct, attributeCode: string): string => {
        if (product.comparableAttributes) {
            return product.comparableAttributes[attributeCode] ?? '';
        }

        return '';
    };

    return (
        <>
            <SystemNotifications theme="full" separated={true}>
                <SystemNotification intent="warn" className="desktop-hide tablet-hide">
                    {labels.mobileCta}
                </SystemNotification>
                {!products.length && <SystemNotification intent="info">{labels.noItemsToCompare}</SystemNotification>}
            </SystemNotifications>

            {!!products.length && (
                <table className="product-compare-grid mobile-hide">
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '30%' }} />
                    </colgroup>
                    <tbody>
                        <tr className="noborder">
                            <th>&nbsp;</th>
                            {products.map((product) => (
                                <td key={product.id}>
                                    <div className="layout-products__container" key={product.id}>
                                        <ButtonIcon
                                            icon="epharma-cross"
                                            intent="secondary"
                                            size="small"
                                            className="product-compare-grid__remove"
                                            label={labels.remove}
                                            onClick={() => removeProduct(product)}
                                        />
                                        <BoxProduct
                                            product={product}
                                            labels={pick(labels, [
                                                'addedToCart',
                                                'logInForPrices',
                                                'startingFrom',
                                                'addToCart',
                                                'quantity',
                                                'readMore',
                                                'outOfStock',
                                            ])}
                                        />
                                    </div>
                                </td>
                            ))}
                        </tr>
                        {comparableAttributes.map((attribute) => (
                            <tr key={attribute.code}>
                                <td>{attribute.label}</td>
                                {products.map((product) => (
                                    <td key={product.id}>
                                        <RenderHTML html={getAttributeValue(product, attribute.code)} nowrapper />
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr className="noborder">
                            <td>&nbsp;</td>
                            {products.map((product) => (
                                <td key={product.id}>
                                    <div className="box-product compact">
                                        <div className="box-product__actions">
                                            <ProductActions
                                                product={product}
                                                backOrders={backOrders}
                                                inBackOrders={
                                                    !!find(
                                                        backOrders.backOrderItems,
                                                        (item) => item.sku === product.sku,
                                                    )
                                                }
                                                labels={pick(labels, [
                                                    'logInForPrices',
                                                    'startingFrom',
                                                    'addToCart',
                                                    'quantity',
                                                    'readMore',
                                                    'outOfStock',
                                                ])}
                                            />
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};

export default ProductCompareList;
