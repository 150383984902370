import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import * as React from 'react';
import { useState } from 'react';
import AddToCartRequest, { IAddToCartRequestData } from 'components/Catalog/Product/requests/AddToCartRequest';
import { useMutation } from 'redux-query-react';
import { HttpStatusCode } from '../../../../enums/HttpStatusCode';
import Img from 'components/Image/Img';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';
import useBackOrders from '../../../../hooks/useBackOrders';
import BackOrderCreateOverlay from 'components/BackOrders/Overylays/BackOrderCreateOverlay';
import { find } from 'lodash';

interface IProductActionsProps {
    sku: string;
    onSuccess: (message: string) => void;
    onError: (message: string) => void;
    onBackOrderAdd: (message: string) => void;
    isLoggedIn: boolean;
    quantityLabel: string;
    addToCartLabel: string;
    loginLabel: string;
    canAddToCart: boolean;
    isSaleable: boolean;
    productBackOrderLabel: string;
}

const ProductActions: React.FunctionComponent<IProductActionsProps> = (props) => {
    const {
        quantityLabel,
        addToCartLabel,
        sku,
        onSuccess,
        onError,
        onBackOrderAdd,
        isLoggedIn,
        loginLabel,
        canAddToCart,
        isSaleable,
        productBackOrderLabel,
    } = props;
    const [{ isPending }, addToCartRequest] = useMutation((data: IAddToCartRequestData) => AddToCartRequest(data));
    const [qty, setQty] = useState<number>(1);
    const [backOrderQty, setBackOrderQty] = useState<undefined | number>();
    const [backOrderMessage, setBackOrderMessage] = useState<undefined | string>();
    const dispatch = useDispatch();
    const backOrders = useBackOrders();

    const handleAddToCart = () => {
        void addToCartRequest({
            sku: sku,
            qty: qty,
        }).then((res) => {
            if (res.status === HttpStatusCode.CREATED) {
                setBackOrderQty(res.body.quantityBackOrder);
                setBackOrderMessage(res.body.backOrderMessage);

                if (res.body.quantityBackOrder > 0) {
                    openBackOrderCreate();
                }

                onSuccess(res.body.message);
            } else {
                onError(res.body.message);
            }
        });
    };

    const openLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    const openBackOrderCreate = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: `backOrderCreate${sku}` }));
    };

    return (
        <div className="product-actions">
            <BackOrderCreateOverlay
                quantity={backOrderQty ?? qty}
                sku={sku}
                inBackOrders={!!find(backOrders.backOrderItems, (item) => item.sku === sku)}
                descriptionLabel={backOrderMessage ?? backOrders.labels.backOrderOverlayDescription}
                continueLabel={backOrders.labels.backOrderOverlayConfirm}
                cancelLabel={backOrders.labels.backOrderOverlayCancel}
                alreadyInBackOrders={backOrders.labels.alreadyInBackOrders}
                inBackOrdersLabel={backOrders.labels.inBackOrdersLabel}
                onBackOrderAdd={onBackOrderAdd}
            />
            <div className="product-actions__row align-left">
                {isLoggedIn && (isSaleable || backOrders.backorderAllowed) && (
                    <>
                        <div className="product-actions__quantity">
                            <label>{quantityLabel}:</label>
                            <input
                                type="number"
                                value={qty}
                                aria-label={quantityLabel}
                                onChange={(e) => {
                                    setQty(e.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <Buttons>
                            {isSaleable && (
                                <Button
                                    intent={'primary'}
                                    onClick={handleAddToCart}
                                    loading={isPending}
                                    disabled={isPending}
                                    icon={'epharma-cart02'}
                                    title={addToCartLabel}
                                />
                            )}
                            {!isSaleable && backOrders.isInitialized && (
                                <Button
                                    intent="secondary"
                                    title={productBackOrderLabel}
                                    onClick={openBackOrderCreate}
                                />
                            )}
                        </Buttons>
                    </>
                )}
                {!isLoggedIn && (
                    <Buttons>
                        <button className="button button-discrete" onClick={openLogin}>
                            <span>
                                <span className="icon">
                                    <Img iconName="icon_lock" />
                                </span>
                                {loginLabel}
                            </span>
                        </button>
                    </Buttons>
                )}
            </div>
        </div>
    );
};

export default ProductActions;
