import React from 'react';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';
import SystemNotifications from 'ekaubamaja-ui/lib/Components/SystemNotifications';
import { LOCALSTORAGE_FILL_BACKORDERS } from 'components/BackOrders/Components/BackOrderHandler';

interface IProps {
    backOrderFillDescription: string;
}

interface IBackOrderFillResponse {
    status: boolean;
    messages: IBackOrderMessage[];
}

interface IBackOrderMessage {
    type: string;
    product_name: string;
    message: string;
}

const mapStateToProps = (state) => {
    return state.entities.backOrderFill as IBackOrderFillResponse;
};

const BackOrdersBanner = (props: IProps) => {
    const { backOrderFillDescription } = props;
    const [{ isFinished }] = useRequest(
        request({
            type: 'backOrderFill',
            url: 'basket/basket/fillBackOrders',
            method: 'GET',
            notApi: true,
        }),
    );

    const backOrderFillResponse = useSelector(mapStateToProps);

    if (isFinished) {
        localStorage.setItem(LOCALSTORAGE_FILL_BACKORDERS, '1');
    }

    if (!isFinished || !backOrderFillResponse.messages.length) {
        return null;
    }

    return (
        <SystemNotifications theme="full">
            <SystemNotification intent="info" align="left" hideIcon closable>
                <p>{backOrderFillDescription}</p>
                <ul>
                    {backOrderFillResponse.messages.map((message, key) => {
                        return (
                            <li key={key}>
                                <strong>{message.product_name}</strong> - <strong>{message.message}</strong>
                            </li>
                        );
                    })}
                </ul>
            </SystemNotification>
        </SystemNotifications>
    );
};

export default BackOrdersBanner;
