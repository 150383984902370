import React from 'react';
import { IListProduct } from 'components/BackOrders/interfaces/BackOrderInterfaces';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import ListProducts from 'components/BackOrders/Components/ListProducts/ListProducts';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { ICartLabels } from 'components/CartView/CartView';
import ProductCampaign from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/ProductCampaign';

interface IProps {
    config: IConfig;
}

interface IConfig {
    labels: {
        backOrders: string;
        medicineLabel: string;
        nonMedicineLabel: string;
        noProductsInBackOrders: string;
    };
    noProductsNotificationImage: string;
    cartLabels: ICartLabels;
}

const mapStateToProps = (state) => {
    return {
        products: state.entities?.backOrderProducts as IListProduct[],
    };
};

const BackOrders = (props: IProps) => {
    const { config } = props;
    const { labels, noProductsNotificationImage, cartLabels } = config;

    const [{ isFinished }] = useRequest(
        request({
            type: 'backOrderProducts',
            url: 'backorders/ajax/listview',
            method: 'GET',
            notApi: true,
        }),
    );

    const { products } = useSelector(mapStateToProps);

    const groupProducts = (products: IListProduct[]) => {
        const medicineProducts: IListProduct[] = [];
        const nonMedicineProducts: IListProduct[] = [];

        if (products) {
            products.forEach((product) => {
                if (product.isMedicine) {
                    medicineProducts.push(product);
                } else {
                    nonMedicineProducts.push(product);
                }
            });
        }

        return { medicineProducts, nonMedicineProducts };
    };

    const { medicineProducts, nonMedicineProducts } = groupProducts(products);

    return (
        <>
            <h2>{labels.backOrders}</h2>
            {!isFinished && <LoadingContent layout={'flex'} size={'large'} />}
            {isFinished && !!products.length && (
                <>
                    {medicineProducts.length > 0 && (
                        <ListProducts
                            products={medicineProducts}
                            title={labels.medicineLabel}
                            icon={'icon_meta02'}
                            cartLabels={cartLabels}
                        />
                    )}
                    {nonMedicineProducts.length > 0 && (
                        <ListProducts
                            products={nonMedicineProducts}
                            title={labels.nonMedicineLabel}
                            icon={'icon_meta05'}
                            cartLabels={cartLabels}
                        />
                    )}
                </>
            )}
            {isFinished && !products.length && (
                <ProductCampaign
                    theme="info"
                    img={noProductsNotificationImage}
                    button={{
                        intent: 'primary',
                        type: 'anchor',
                        href: '/',
                        title: cartLabels.enterStore,
                    }}
                >
                    <h2>{labels.noProductsInBackOrders}</h2>
                    <p>
                        {`${cartLabels.startShopping} `}
                        <a href="/">{cartLabels.here}</a>
                    </p>
                </ProductCampaign>
            )}
        </>
    );
};

export default BackOrders;
