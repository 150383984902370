import { request } from 'data/requests/request';

interface IBackOrderUpdateRequestData {
    sku: string;
    quantity: number;
}

export const UpdateBackOrderRequest = (data: IBackOrderUpdateRequestData) =>
    request({
        url: 'backorders/ajax/update',
        method: 'POST',
        notApi: true,
        data: data,
        type: 'backOrderProducts',
    });
