import React, { useEffect, useState } from 'react';
import { pick } from 'lodash';
import IProduct from 'components/Catalog/interfaces/IProduct';
import ProductActions from 'components/Catalog/Product/List/BoxProduct/ProductActions';
import useCartSummary from '../../../../hooks/useCartSummary';
import { find } from 'lodash';
import Img from 'components/Image/Img';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';
import ProductBadges from 'components/Catalog/Product/List/BoxProduct/ProductBadges';
import ProductLabels from 'components/Catalog/Product/List/BoxProduct/ProductLabels';
import useBackOrders from '../../../../hooks/useBackOrders';

interface IProps {
    product: IProduct;
    labels: {
        addedToCart: string;
        logInForPrices: string;
        startingFrom: string;
        addToCart: string;
        quantity: string;
        readMore: string;
        outOfStock: string;
    };
}

const BoxProduct: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;
    const { products } = useCartSummary();
    const backOrders = useBackOrders();
    const [addedToCart, setAddedToCart] = useState(false);
    const [inBackOrders, setInBackOrders] = useState(false);

    useEffect(() => {
        const inCart = find(products, (item) => item.sku === product.sku);
        const inBackOrders = find(backOrders.backOrderItems, (item) => item.sku === product.sku);

        setAddedToCart(!!inCart);
        setInBackOrders(!!inBackOrders);
    }, [products, backOrders]);

    return (
        <div className="box-product">
            <div className="box-product__main">
                <a href={product.url} className="box-product__link">
                    {product.name}
                </a>
                <div className="box-product__image">
                    {!!product.labels.length && <ProductLabels labels={product.labels} />}
                    {!!product.badges.length && <ProductBadges badges={product.badges} />}
                    <span style={{ backgroundImage: `url(${product.image})` }}></span>
                </div>
                <div className="box-product__content">
                    <div className="box-product__text">
                        <div className="box-product__title">{product.name}</div>
                        {!!product.attributeSetLabel.length && (
                            <div className="box-product__description">
                                <p className="box-product__description-primary">{product.attributeSetLabel}</p>
                            </div>
                        )}
                        {addedToCart && (
                            <div className="box-product__cart">
                                <Img iconName="icon_check" /> <RenderHTML html={labels.addedToCart} nowrapper />
                            </div>
                        )}
                        {!addedToCart && inBackOrders && (
                            <div className="box-product__cart">
                                <Img iconName="icon_check" />{' '}
                                <RenderHTML html={backOrders.labels.inBackOrdersLabel} nowrapper />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="box-product__actions">
                <ProductActions
                    backOrders={backOrders}
                    product={product}
                    inBackOrders={inBackOrders}
                    labels={pick(labels, [
                        'logInForPrices',
                        'startingFrom',
                        'addToCart',
                        'quantity',
                        'readMore',
                        'outOfStock',
                    ])}
                />
            </div>
        </div>
    );
};

export default BoxProduct;
